@media print {
  @page {
    /*size: A4 landscape;*/
    size: A4 landscape;
    margin: 10mm 10mm;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    background: #fff;
  }

  body {
    color: #000 !important;
  }

  .ap .header {
    display: none;
  }

  .ap .print-header {
    display: block;
    position: fixed;
    width: 100%;
    height: 10mm;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    background: #fff;
    z-index: 9999;
  }

  .ap .print-header__logo {
    float: right;
  }

  .ap .print-header img {
    height: 10mm;
    width: auto;
  }

  .ap .main {
    padding: 0 !important;
  }

  aside.sidebar {
    display: none !important;
  }

  .workspace-common,
  .workspace-common__content,
  .workspace-common__content-scroller,
  .workspace__main-section-wrapper,
  .workspace-content__body {
    overflow: visible !important;
  }

  .workspace-content__control-bar,
  .workspace-common__content-footer {
    display: none !important;
  }

  .workspace-content,
  .workspace-content__body,
  .calendar-row--horizontal,
  .calendar-row--vertical,
  .calendar__tables {
    width: auto !important;
    height: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 20px !important;
    background: #fff;
  }

  .calendar__tables {
    background: transparent !important;
    border: 0 !important;
    border-radius: 0 !important;
  }

  .assignment-z-table__title ~ .assignment-z-table__title {
    display: block;
    position: relative;
    page-break-inside: avoid;
    page-break-before: always;
  }

  .assignment-z-table__project-filter,
  .z-table-searchbar {
    display: none !important;
  }

  .z-table .z-table-header {
    position: relative;
    top: auto;
  }

  .z-table .background--transparent,
  .z-table .z-table__input--empty {
    background: transparent !important;
    border-color: transparent !important;
  }
}
